import { clearCookie, getCookie } from './common';

let trigger = false;
function handleResponse(response) {
  if (response.status === 401 && !trigger && getCookie('admin_token')) {
    trigger = true;
    clearCookie('admin_token');
    window.location.reload();
  }
  return response;
}
function get(url) {
  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${getCookie('admin_token')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers,
  };
  return fetch(url, requestOptions).then(res => handleResponse(res));
}

function post(url, body) {
  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${getCookie('admin_token')}`,
  };

  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  };

  return fetch(url, requestOptions).then(res => handleResponse(res));
}

function upload(url, body) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    authorization: `Bearer ${getCookie('admin_token')}`,
  };

  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  };

  return fetch(url, requestOptions).then(res => handleResponse(res));
}

function put(url, body) {
  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${getCookie('admin_token')}`,
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(res => handleResponse(res));
}

function customAuthPut(url, body, token) {
  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${token}`,
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(res => handleResponse(res));
}

function _delete(url, body) {
  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${getCookie('admin_token')}`,
  };
  const requestOptions = {
    method: 'DELETE',
    headers,
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(res => handleResponse(res));
}

function patch(url, body) {
  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${getCookie('admin_token')}`,
  };
  const requestOptions = {
    method: 'PATCH',
    headers,
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(res => handleResponse(res));
}

export const Fetch = {
  get,
  post,
  put,
  customAuthPut,
  delete: _delete,
  patch,
  upload,
};
