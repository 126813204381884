import { setCookie } from '../helpers/common';
import { Fetch } from '../helpers/fetchWrapper';

const commonService = {
  _url: `${process.env.REACT_APP_BASE_API_URL}/common`,
  async login(email, password) {
    let res = await Fetch.post(`${this._url}/login`, {
      email,
      password,
    });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      setCookie('admin_token', res.token);
      return res;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async logout() {
    const res = await Fetch.post(`${this._url}/logout`, {});
    if (res.status >= 200 && res.status < 300) {
      return true;
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async getCurrentUser() {
    const res = await Fetch.get(`${this._url}/me`);
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async resetPassword(data) {
    let res = await Fetch.post(`${this._url}/reset-password`, data);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    res = await res.json();
    throw new Error(res.message ?? 'Something went wrong');
  },
};

export default commonService;
