/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import adminService from 'services/admin.service';

import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import Select from 'components/atoms/Select';

function EditPrice({ onClose = () => {}, price }) {
  const [form] = useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);

  useEffect(() => {
    if (price) {
      const s = { ...price };
      s.resellerId = { label: price?.resellerId?.email, value: price?.resellerId?._id };
      s.serviceId = {
        label: `Service Code:${price?.serviceId?.serviceCode},
      Provider: ${price?.serviceId?.providerId?.providerName}`,
        value: price?.serviceId?._id,
        price: price?.serviceId?.price,
      };
      // s.serviceId = {
      //   label: `Service Code:${price?.serviceId?.serviceCode},
      // Provider: ${price?.serviceId?.providerId?.providerName}`,
      //   value: price?.serviceId?._id,
      //   price: price?.serviceId?.price,
      // };
      form.setFieldsValue(s);

      setState(s);
    }
  }, [price]);

  const handleServicesSearch = async __ => {
    try {
      const response = await adminService.getServices({
        page: 1,
        pageSize: 10,
        searchText: __,
        startDate: '',
        endDate: '',
        filterProviders: '',
      });
      const options = response?.services?.map(_ => ({
        value: _?._id,
        label: `Service Code:${_?.serviceCode},
        Provider: ${_?.providerId?.providerName}`,
        price: _.price,
      }));
      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };
  const handleResellerSearch = async __ => {
    try {
      const response = await adminService.getResellers({
        page: 1,
        pageSize: 10,
        searchText: __,
        startDate: '',
        endDate: '',
        filterProviders: '',
      });
      const options = response?.resellers?.map(_ => ({
        value: _?._id,
        label: _?.email,
      }));
      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };
  const onSubmit = async data => {
    const payload = {
      resellerId: data.resellerId.value,
      serviceId: data.serviceId.value,
      discount: data?.discount ?? 0,
    };
    try {
      setLoading(true);
      const res = !price
        ? await adminService.createPricelist(payload)
        : await adminService.updatePricelist(price?._id, payload);
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: res?.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      onTouched={_ => {
        if (Object.keys(_).includes('serviceId')) {
          form.setFieldsValue({ price: _.serviceId.price });
          setState(__ => ({ ...__, ..._, price: _.serviceId.price }));
        } else if (Object.keys(_).includes('discount')) {
          form.setFieldsValue({ discounted_price: state.price - (_.discount / 100) * state.price });
          setState(__ => ({ ...__, ..._, discounted_price: state.price - (_.discount / 100) * state.price }));
        } else {
          setState(__ => ({ ...__, ..._ }));
        }
      }}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label="Reseller"
          name="resellerId"
          open
          async
          defaultOptions
          filterOption={false}
          loadOptions={handleResellerSearch}
          placeholder="Select Reseller"
          rules={[{ required: true, message: 'Please select Reseller' }]}>
          <Select />
        </Form.Item>
        {/* <Form.Item
          sm
          type="text"
          label="Service Provider"
          name="providerId"
          open
          async
          defaultOptions
          filterOption={false}
          loadOptions={handleProviderSearch}
          placeholder="Select Service Provider"
          rules={[{ required: true, message: 'Please select service provider' }]}>
          <Select />
        </Form.Item> */}
        <Form.Item
          sm
          type="text"
          label="Services"
          name="serviceId"
          open
          async
          defaultOptions
          filterOption={false}
          loadOptions={handleServicesSearch}
          placeholder="Select Service"
          rules={[{ required: true, message: 'Please select service' }]}>
          <Select />
        </Form.Item>

        <Form.Item
          type="number"
          sm
          label="Discount"
          name="discount"
          placeholder="Discount"
          prefix="%"
          rules={[
            {
              min: 0,
              message: 'Minimum value should be 0',
            },
            {
              max: 100,
              message: 'Value should be less than or equal to 100',
            },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          disabled
          type="number"
          sm
          label="Original Price"
          name="price"
          placeholder="Price"
          prefix="€"
          value={state?.serviceId?.price ?? null}>
          <Field />
        </Form.Item>
        <Form.Item
          disabled
          type="number"
          sm
          label="Discounted Price"
          name="discounted_price"
          placeholder="Price"
          value={
            state?.serviceId?.price && state?.discount
              ? state?.serviceId?.price - (state?.serviceId?.price * state?.discount) / 100
              : state?.serviceId?.price
          }
          prefix="€">
          <Field />
        </Form.Item>
      </Grid>

      <Button
        disabled={loading}
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        {price ? 'Update' : 'Create'}
      </Button>
    </Form>
  );
}

export default EditPrice;
