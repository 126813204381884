/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import { LangConverter } from 'helpers/common';
import Heading from 'components/atoms/Heading';
import Logo from 'components/atoms/Logo';

import Button from 'components/atoms/Button';
import { useContextHook } from 'use-context-hook';
import Grid from 'components/atoms/Grid';
import GridCol from 'components/atoms/GridCol';
import LoginTemplate from '../../templates/LoginTemplate';
import Field from '../../molecules/Field';
import Form, { useForm } from '../../molecules/Form';

import { SubTitle, LoginHead, FormHolder, StyledForm } from './Login.styles';

function Login() {
  const { lang } = useContextHook(AuthContext, { lang: 1 });
  const [form] = useForm();
  const [forgetPassword, setForgetPassword] = useState(false);
  const { onLogin, onResetPassword, user_loading } = useContextHook(AuthContext, {
    onLogin: 1,
    user_loading: 1,
    onResetPassword: 1,
  });
  return (
    <LoginTemplate>
      <LoginHead>
        <Logo />
      </LoginHead>

      {!forgetPassword && (
        <FormHolder>
          <Heading level={1}>{LangConverter('Sign in', lang.value)}</Heading>
          <SubTitle>{LangConverter('Hello there! Sign in', lang.value)}</SubTitle>

          <StyledForm form={form} onSubmit={onLogin}>
            <Form.Item
              type="text"
              label={LangConverter('Email', lang.value)}
              name="email"
              placeholder={LangConverter('Your Email', lang.value)}
              prefix={<i className="material-icons-outlined">email</i>}
              rules={[{ required: true }]}>
              <Field />
            </Form.Item>
            <Form.Item
              type="password"
              label={LangConverter('Password', lang.value)}
              name="password"
              placeholder={LangConverter('Your Password', lang.value)}
              prefix={<i className="material-icons-outlined">lock</i>}
              rules={[{ required: true }]}>
              <Field />
            </Form.Item>
            <p
              style={{
                float: 'right',
              }}>
              <small>
                <a
                  href="#"
                  type="link"
                  onClick={e => {
                    e.preventDefault();
                    setForgetPassword(true);
                  }}>
                  {LangConverter('Forget password ?', lang.value)}
                </a>
              </small>
            </p>
            <br />
            <Button loading={user_loading} type="primary" htmlType="submit" width={150} css="margin: 0 auto;">
              {LangConverter('Sign in', lang.value)}
            </Button>{' '}
            <br />
          </StyledForm>
        </FormHolder>
      )}
      {forgetPassword && (
        <FormHolder>
          <Heading level={1}>{LangConverter('Reset Password', lang.value)}</Heading>
          <SubTitle>
            {LangConverter('Enter your email so we can send you a password reset email', lang.value)} <br />
          </SubTitle>
          <StyledForm form={form} onSubmit={_ => onResetPassword(_, () => setForgetPassword(false))}>
            <Grid lg={24} xl={25}>
              <GridCol lg={24} xl={24}>
                <Form.Item
                  type="text"
                  label={LangConverter('Email', lang.value)}
                  name="email"
                  placeholder={LangConverter('Your Email', lang.value)}
                  prefix={<i className="material-icons-outlined">email</i>}
                  rules={[{ required: true }]}>
                  <Field />
                </Form.Item>
              </GridCol>
            </Grid>
            <Button loading={user_loading} type="primary" htmlType="submit" width={150} css="margin: 0 auto;">
              {LangConverter('Reset Password', lang.value)}
            </Button>
          </StyledForm>
          <div>
            <small>
              {LangConverter('Already have an account? ', lang.value)}
              <a
                href="#"
                type="link"
                onClick={e => {
                  e.preventDefault();
                  setForgetPassword(false);
                }}>
                {LangConverter('Login', lang.value)}
              </a>
            </small>
          </div>
        </FormHolder>
      )}
    </LoginTemplate>
  );
}

export default Login;
