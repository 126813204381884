/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import Styled from 'styled-components/macro';

import { useMediaPredicate } from 'react-media-hook';
import { SideNavContext } from 'context/sideNavContext';
import { LoadingContext } from 'context/loadingContext';
// import LogoP from 'assets/images/logo-white.png';
import LogoP from 'assets/images/logo_icon.png';
import default_logo from 'assets/images/logo_white.png';
import sideNavData from 'nav.json';
import UserActions from 'components/organisms/UserActions';
import { useContextHook } from 'use-context-hook';
import { AuthContext } from 'context/authContext';
import { LangConverter } from 'helpers/common';
import { SideNavbar, Nav, Ul, CloseButton, LogoHolder, Logo, SearchField, StyledField } from './SideNav.styles';
import SubMenu from './SubMenu';

function Navbar() {
  const { lang } = useContextHook(AuthContext, { lang: 1 });
  const { toggleSideNav, sideNavState } = useContextHook(SideNavContext, {
    toggleSideNav: 1,
    sideNavState: 1,
  });
  const [searchText, setSearchText] = useState('');
  const { isLoading } = useContextHook(LoadingContext, {
    isLoading: 1,
  });
  const MaxWidth991 = useMediaPredicate('(max-width: 991px)');
  const { allowedPages, user } = useContextHook(AuthContext, { allowedPages: 1, user: 1 });
  useEffect(() => {
    if (!sideNavState) {
      document.body.classList.remove('nav-active');
    }
  }, [sideNavState]);
  const sideBarItems = useMemo(
    () =>
      sideNavData
        ?.filter(item => allowedPages.includes(item.file) && !item.sideNavHide)
        ?.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase()))
        .map((item, index) => <SubMenu item={item} key={index} />),
    [searchText, isLoading, sideNavData, allowedPages],
  );
  return (
    <SideNavbar css={isLoading && 'background:var(--dark);'} $loading={isLoading}>
      <LogoHolder>
        <Logo to="/">
          <img src={LogoP} alt="admin" className="logo-small" />
          <img
            src={user?.company_id?.company_logo ? user?.company_id?.company_logo : default_logo}
            alt="admin"
            className="logo-full"
          />
        </Logo>
      </LogoHolder>
      {MaxWidth991 && (
        <CloseButton onClick={toggleSideNav}>
          <i className="material-icons-outlined">close</i>
        </CloseButton>
      )}
      <SearchField>
        <StyledField
          type="search"
          placeholder={LangConverter('Search...', lang.value)}
          value={searchText}
          onChange={({ target: { value } }) => setSearchText(value)}
          prefix={<i className="material-icons-outlined">search</i>}
        />
      </SearchField>

      <Nav>
        <Ul>{sideBarItems}</Ul>
      </Nav>
      <UserActions toggleSideNav={toggleSideNav} />
    </SideNavbar>
  );
}
export default Navbar;
