/* eslint-disable no-unreachable */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import adminService from 'services/admin.service';

import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import { LangConverter } from 'helpers/common';
import Select from 'components/atoms/Select';
import GridCol from 'components/atoms/GridCol';
import resellerService from 'services/resellers.service';
import { useTranslation } from 'helpers/useTranslation';

function PlaceOrderForm({ onClose = () => {}, order }) {
  const [form] = useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { refetch, user } = useContextHook(AuthContext, ['refetch', 'user']);
  const { lang } = useContextHook(AuthContext, { lang: 1 });
  const { t } = useTranslation();
  useEffect(() => {
    if (order) {
      const services = order.servicesDetail?.map(itm => {
        form.setFieldsValue({ [`units_${itm?.serviceId?._id}`]: itm?.units });
        return {
          label: `${t('Service Code')}: ${itm?.serviceId?.serviceCode} ${t('Provider')}:${
            itm?.serviceId?.providerId?.providerName
          }`,
          value: itm?.serviceId?._id,
          serviceCode: itm?.serviceId?.serviceCode,
          price: itm?.serviceId?.price,
          discountedAmount:
            itm?.originalAmount / itm?.units - ((itm?.originalAmount / itm?.units) * itm?.discount) / 100,
          units: itm?.units,
          totalPrice: itm?.amount,
          // totalPrice:user?.typeOfAuthorizedCenter === 'basic' ? _?.price : _?.discountedAmount
        };
      });
      const s = {
        serviceId: services,
        paymentProof: order?.paymentProof ? order?.paymentProof : '',
        paymentCommitment: order?.paymentCommitment ?? '',
      };
      form.setFieldsValue(s);
      setState(prev => ({ ...prev, ...s }));
    }
  }, [order]);

  const totalAmount = useMemo(() => {
    if (state?.serviceId?.length) {
      const total = state.serviceId.reduce((accumulator, currentValue) => {
        // Check if currentValue has a 'totalPrice' property and it's a number
        if (currentValue && typeof currentValue?.totalPrice === 'number') {
          return accumulator + currentValue?.totalPrice;
        }
        return accumulator;
      }, 0); // Initialize accumulator with 0
      return total;
    }
    return 0;
  }, [state]);
  const handleServices = async __ => {
    try {
      const response = await resellerService.getResellerPrices({
        page: 1,
        pageSize: 10,
        searchText: __,
        startDate: '',
        endDate: '',
        filterProviders: '',
      });
      const options = response?.items?.map(_ => ({
        value: _?._id,
        label: `${t('Service Code')}: ${_?.serviceCode} ${t('Provider')}:${_?.providerId?.providerName}`,
        serviceCode: _?.serviceCode,
        providerId: _?.providerId,
        providerName: _?.providerId?.providerName,
        price: _?.price,
        discountedAmount: _?.discountedAmount,
        totalPrice: user?.typeOfAuthorizedCenter === 'basic' ? _?.price : _?.discountedAmount,
        units: null,
      }));
      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };
  const onSubmit = async () => {
    const servicesDetail = state?.serviceId?.map(itm => ({
      serviceId: itm?.value,
      units: itm?.units,
      originalAmount: itm?.price * itm?.units,
    }));
    const payload = { servicesDetail, paymentProof: state?.paymentProof, paymentCommitment: state?.paymentCommitment };
    try {
      // return;
      setLoading(true);
      const res = !order
        ? await resellerService.placeOrder(payload)
        : await resellerService.updateOrder(order._id, payload);
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: res?.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  const handleSelectServices = ({ target: { value } }) => {
    form.setFieldsValue({ serviceId: value });
  };

  const handleUnits = (serviceId, units) => {
    const services = state?.serviceId?.map(itm => {
      if (itm?.value === serviceId) {
        let newPrice = units * itm?.discountedAmount;
        if (user?.typeOfAuthorizedCenter === 'basic') {
          newPrice = units * itm?.price;
        }
        return { ...itm, totalPrice: newPrice, units };
      }
      return itm;
    });
    form.setFieldsValue({ [`units_${serviceId}`]: units });
    setState(prev => ({ ...prev, serviceId: services }));
  };
  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      onTouched={_ => {
        setState(__ => ({ ...__, ..._ }));
      }}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <GridCol xs={1} sm={12}>
          <Form.Item
            sm
            type="text"
            label={t('Services')}
            name="serviceId"
            open
            async
            defaultOptions
            isMulti
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            filterOption={false}
            onChange={handleSelectServices}
            loadOptions={handleServices}
            placeholder={t('Select Service')}
            rules={[{ required: true, message: t('Please select a service') }]}>
            <Select />
          </Form.Item>
        </GridCol>
      </Grid>
      <div>
        {state?.serviceId?.map(itm => (
          <Grid xs={1} md={12} gap={10}>
            <GridCol xs={1} sm={2} md={3}>
              <Form.Item
                type="number"
                sm
                label="Service Code"
                name="serviceId"
                placeholder="Service Code"
                disabled
                value={itm?.serviceCode}>
                <Field />
              </Form.Item>
            </GridCol>
            <GridCol xs={1} sm={2} md={3}>
              <Form.Item
                type="number"
                sm
                label="Units"
                name={`units_${itm?.value}`}
                value={itm?.units}
                placeholder="Units"
                onChange={({ target: { value } }) => {
                  handleUnits(itm?.value, value);
                }}
                rules={[
                  {
                    required: true,
                    message: 'Minimum value should be 1',
                  },
                  {
                    min: 1,
                    message: 'Minimum value should be 1',
                  },
                ]}>
                <Field />
              </Form.Item>
            </GridCol>
            <GridCol xs={1} sm={2} md={3}>
              <Form.Item
                type="number"
                sm
                label="Per Unit Price"
                name="price"
                placeholder="Price"
                disabled
                value={user?.typeOfAuthorizedCenter === 'basic' ? itm?.price : itm?.discountedAmount}
                prefix="€">
                <Field />
              </Form.Item>
            </GridCol>
            <GridCol xs={1} sm={2} md={3}>
              <Form.Item
                type="number"
                sm
                label="Total Price"
                name="totalPrice"
                placeholder="units * price"
                disabled
                value={itm?.totalPrice}
                prefix="€">
                <Field />
              </Form.Item>
            </GridCol>
          </Grid>
        ))}
      </div>

      {!!state?.serviceId?.length && (
        <>
          <GridCol xs={1}>
            <Form.Item
              type="number"
              sm
              label="Total Order Amount"
              name="totalOrderAmount"
              placeholder="Total Order Amount"
              disabled
              value={totalAmount}
              prefix="€">
              <Field />
            </Form.Item>
          </GridCol>
          <GridCol xs={1}>
            {user?.typeOfAuthorizedCenter === 'premium' && (
              <Form.Item
                type="chooseFile"
                label="Payment commitment self-certification"
                name="paymentCommitment"
                pdf
                value={state?.paymentCommitment}
                onChange={({ target }) => {
                  setState(prev => ({ ...prev, paymentCommitment: target?.value }));
                  form.setFieldsValue({ paymentCommitment: target?.value });
                }}
                // rules={[
                //   {
                //     required: () => {
                //       if (user?.typeOfAuthorizedCenter !== 'gold') {
                //         return true;
                //       }
                //       return false;
                //     },
                //     message: 'Payment Proof is required',
                //   },
                // ]}
              >
                <Field />
              </Form.Item>
            )}
            <Form.Item
              type="chooseFile"
              label="Payment Proof"
              name="paymentProof"
              pdf
              value={state?.paymentProof}
              onChange={({ target }) => {
                setState(prev => ({ ...prev, paymentProof: target?.value }));
                form.setFieldsValue({ paymentProof: target?.value });
              }}
              // rules={[
              //   {
              //     required: () => {
              //       if (user?.typeOfAuthorizedCenter !== 'gold') {
              //         return true;
              //       }
              //       return false;
              //     },
              //     message: 'Payment Proof is required',
              //   },
              // ]}
            >
              <Field />
            </Form.Item>
          </GridCol>
        </>
      )}
      <Button
        disabled={loading}
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        {LangConverter(order ? 'Update' : 'Save', lang.value)}
      </Button>
    </Form>
  );
}

export default PlaceOrderForm;
