/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import 'styled-components/macro';
import PropTypes from 'prop-types';

import { StyledTabs, Wrap, StyledTabList, TabBtn, StyledTab, StyledTabPanels, StyledTabPanel } from './DataTabs.styles';

const propTypes = {
  data: PropTypes.array,
};

function DataTabs({ data = [], verticalTabs, uploadBtn, extraCss = () => {}, css2, _activeTab = 0 }) {
  const [activeTab, setActiveTab] = useState(_activeTab);
  return (
    <StyledTabs verticalTabs={verticalTabs}>
      <Wrap uploadBtn>
        <StyledTabList css={css2}>
          {data.map((tab, index) => (
            <TabBtn
              key={tab.label}
              onClick={() => {
                setActiveTab(index);
              }}>
              <StyledTab css={extraCss(activeTab === index)} active={activeTab === index}>
                {tab.label}
              </StyledTab>
            </TabBtn>
          ))}
        </StyledTabList>
        {uploadBtn ?? uploadBtn}
      </Wrap>

      <StyledTabPanels
        css={`
          width: 100%;
        `}>
        <StyledTabPanel key={data?.find((x, i) => i === activeTab)?.label} active>
          {data?.find((x, i) => i === activeTab)?.content}
        </StyledTabPanel>
      </StyledTabPanels>
    </StyledTabs>
  );
}

DataTabs.propTypes = propTypes;

export default DataTabs;
