/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { LanguageData } from 'helpers/constants';
import { Language, StyledLanguageDropDown } from './LanguageDropDown.styles';

function LanguageDropDown({ onClick = () => {}, value }) {
  const [languageDrop, setLanguageDrop] = useState(false);
  const flag = LanguageData.find(itm => itm.value === value?.value);

  return (
    <Language
      open={languageDrop}
      onClick={() => {
        setLanguageDrop(!languageDrop);
      }}>
      <button type="button">
        <img src={flag?.img} alt="img description" width={24} height={24} />
      </button>
      <div className="DropDownLanguage">
        <StyledLanguageDropDown>
          {LanguageData.map((elem, ind) => (
            <li
              key={ind}
              onClick={() => {
                // e.stopPropagation();
                onClick(elem);
              }}>
              {elem.img && (
                <span className="flag">
                  <img src={elem?.img} alt={elem?.language} />
                </span>
              )}
              {elem.language}
            </li>
          ))}
        </StyledLanguageDropDown>
      </div>
    </Language>
  );
}

export default LanguageDropDown;
