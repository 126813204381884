/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unreachable */
import Toast from 'components/molecules/Toast';
import { LangConverter, clearCookie, getCookie, setCookie } from 'helpers/common';
import { useCancellablePromise } from 'helpers/promiseHandler';
import { useEffect, useState } from 'react';
import commonService from 'services/common.service';
import { createContextHook } from 'use-context-hook';
import { LanguageData } from 'helpers/constants';

const context = {};
export const AuthContext = createContextHook(context);
export function AuthContextProvider(props) {
  const [lang, setLang] = useState(
    JSON.parse(
      localStorage.getItem('_lang') ??
        `${JSON.stringify({
          label: LanguageData[0].language,
          value: LanguageData[0].value,
          img: LanguageData[0].img,
        })}`,
    ),
  );

  const [isLoggedIn, setIsLoggedIn] = useState(!!getCookie('admin_token'));
  const [allowedPages, setAllowedPages] = useState(JSON.parse(getCookie('allowed_pages')) ?? []);
  const [user, setUser] = useState({});
  const [loading_user, setLoadingUser] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [refetch_User, setRefetch_User] = useState(false);
  const reFetch = () => setFetch(!fetch);
  const { cancellablePromise } = useCancellablePromise();

  const onLogout = () => {
    if (getCookie('admin_token')) clearCookie('admin_token');
    setIsLoggedIn(false);
    setUser({});
  };
  const onLogin = ({ email, password }) =>
    commonService
      .login(email, password)
      .then(res => {
        let pages = [...res.permissions.filter(p => p.includes('.nav')).map(p => p.split('.')[0])];
        pages = pages.filter(p => p !== 'no-permissions');
        setAllowedPages(pages);
        setCookie('allowed_pages', JSON.stringify(pages));
        setIsLoggedIn(() => true);
        setUser(() => ({
          ...res.user,
          role_type: res.role_type,
          permissions: res.permissions,
        }));
        return true;
      })
      .catch(({ message }) => {
        setIsLoggedIn(false);
        Toast({
          message,
          type: 'error',
        });
      });

  const onResetPassword = (_, callback) =>
    commonService
      .resetPassword(_)
      .then(res => {
        callback(res);
        Toast({
          type: 'warning',
          message: 'Check your email for the reset link',
        });
      })
      .catch(({ message }) => {
        Toast({
          message,
          type: 'error',
        });
      });

  useEffect(() => {
    if (isLoggedIn) {
      setLoadingUser(true);
      cancellablePromise(commonService.getCurrentUser())
        .then(res => {
          let pages = [...res.permissions.filter(p => p.includes('.nav')).map(p => p.split('.')[0])];
          pages = pages.filter(p => p !== 'no-permissions');
          setAllowedPages(pages);
          setCookie('allowed_pages', JSON.stringify(pages));
          setUser(() => ({
            ...res.user,
            role_type: res.role_type,
            permissions: res.permissions,
          }));
          setLoadingUser(false);
        })
        .catch(err => {
          setAllowedPages(['no-permissions']);
          setCookie('allowed_pages', JSON.stringify(['no-permissions']));
          setLoadingUser(false);
          Toast({
            type: 'error',
            message: err.message,
          });
        });
    }
  }, [isLoggedIn, refetch_User]);
  const refetchUser = () => setRefetch_User(_ => !_);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const passreset = urlParams.get('passreset');
    if (passreset === 'true') {
      Toast({
        autoClose: 10000,
        type: 'success',
        message: 'Please check your email for new password',
      });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        setIsLoggedIn,
        onLogout,
        onLogin,
        reFetch,
        refetch: reFetch,
        refetchUser,
        setLang: x => {
          localStorage.setItem('_lang', JSON.stringify(x));
          setLang(x);
        },
        onResetPassword,
        lang,
        allowedPages,
        fetch,
        isLoggedIn,
        loading_user,
        user,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
}
