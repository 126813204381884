/* eslint-disable no-unused-vars */
import React from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import { useMediaPredicate } from 'react-media-hook';
import Header from 'components/molecules/Header';
import ModalContainer from 'components/molecules/ModalContainer';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/atoms/Button';

import { useContextHook } from 'use-context-hook';
import RolesForm from 'components/organisms/RolesForm';
import PermissionForm from 'components/organisms/PermissionsForm';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';

import { LangConverter } from 'helpers/common';
import { usePermissions } from 'helpers/usePermissions';

import ResellerForm from 'components/organisms/ResellerForm';
import adminService from 'services/admin.service';
import LanguageDropDown from 'components/organisms/LanguageDropDown';
import ServicesForm from 'components/organisms/ServicesForm';
import ServiceProviderForm from 'components/organisms/ServiceProviderForm';
import CreatePrice from 'components/organisms/CreatePrice';
import CreateStudent from 'components/organisms/CreateStudent';
import CreateJobHouseUser from 'components/organisms/CreateJobHouseUser';
import AdminForm from 'components/organisms/AdminForm';
import PlaceOrderForm from 'components/organisms/PlaceOrderForm';
import EditPrice from 'components/organisms/EditPrice';
import SellerForm from 'components/organisms/SellerForm';
import PageButtons from '../nav.json';

function TopBar({ title: pageTitle }) {
  // eslint-disable-next-line prefer-const
  let { view: title } = useParams();
  const { hasPermission } = usePermissions();
  const { lang, setLang, user } = useContextHook(AuthContext, {
    user: 1,
    lang: 1,
    setLang: 1,
  });
  title = LangConverter(PageButtons.find(itm => itm.file === pageTitle)?.name ?? title.split('?')[0], lang.value);

  const handleChange = e => {
    const val = {
      label: e.language,
      value: e.value,
      img: e.img,
    };
    setLang(val);
  };

  const buttons = PageButtons.find(({ file }) => file === pageTitle)?.buttons ?? [];

  const restorePermissions = async () => {
    try {
      const res = await adminService.restorePermissions();
      if (res) {
        Toast({
          type: 'success',
          message: 'Permissions restored successfully',
        });
      }
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex?.message,
      });
    }
  };
  return (
    <Header title={title.split('-').join(' ')}>
      <LanguageDropDown value={lang} onClick={handleChange} />
      {hasPermission('permissions.nav') && buttons.includes('permissions.create') && (
        <ModalContainer
          lg
          title={LangConverter('Create Permission', lang.value)}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{LangConverter('Create Permission', lang.value)}</span>
            </Button>
          )}
          content={({ onClose }) => <PermissionForm onClose={onClose} />}
        />
      )}
      {hasPermission('permissions.nav') && buttons.includes('permissions.restore') && (
        <Button
          type="outline"
          onClick={restorePermissions}
          iconMobile
          prefix={<i className="material-icons-outlined">restore</i>}>
          <span className="text">{LangConverter('Restore Permissions', lang.value)}</span>
        </Button>
      )}
      {buttons.includes('roles.nav') && (
        <ModalContainer
          lg
          title={LangConverter('Create Role', lang.value)}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{LangConverter('Create Role', lang.value)}</span>
            </Button>
          )}
          content={({ onClose }) => <RolesForm onClose={onClose} />}
        />
      )}
      {buttons.includes('resellers.create') && (
        <ModalContainer
          lg
          title={LangConverter('Create Reseller', lang.value)}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{LangConverter('Create Reseller', lang.value)}</span>
            </Button>
          )}
          content={({ onClose }) => <ResellerForm onClose={onClose} />}
        />
      )}
      {buttons.includes('resellers.create-seller') && (
        <ModalContainer
          lg
          title={LangConverter('Create Seller', lang.value)}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{LangConverter('Create Seller', lang.value)}</span>
            </Button>
          )}
          content={({ onClose }) => <SellerForm onClose={onClose} />}
        />
      )}
      {buttons.includes('sellers.create-seller') && (
        <ModalContainer
          lg
          title={LangConverter('Create Seller', lang.value)}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{LangConverter('Create Seller', lang.value)}</span>
            </Button>
          )}
          content={({ onClose }) => (
            <SellerForm
              onClose={onClose}
              isReseller
              // reseller={{
              //   resellerEmail: user?.email,
              //   resellerName: `${user?.firstName} ${user?.lastName}`,
              //   resellerId: user?._id,
              // }}
            />
          )}
        />
      )}
      {hasPermission('admins.nav') && buttons.includes('admins.create') && (
        <ModalContainer
          lg
          title={LangConverter('Create Admin', lang.value)}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{LangConverter('Create Admin', lang.value)}</span>
            </Button>
          )}
          content={({ onClose }) => <AdminForm onClose={onClose} />}
        />
      )}
      {buttons.includes('services.create') && (
        <ModalContainer
          md
          title={LangConverter('Create Service', lang.value)}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{LangConverter('Create Service', lang.value)}</span>
            </Button>
          )}
          content={({ onClose }) => <ServicesForm onClose={onClose} />}
        />
      )}
      {buttons.includes('service-providers.create') && (
        <ModalContainer
          md
          title={LangConverter('Create Service Provider', lang.value)}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{LangConverter('Create Service Provider', lang.value)}</span>
            </Button>
          )}
          content={({ onClose }) => <ServiceProviderForm onClose={onClose} />}
        />
      )}
      {buttons.includes('price-list.create') && (
        <ModalContainer
          xl
          title={LangConverter('Create Reseller Price List', lang.value)}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{LangConverter('Create Price List', lang.value)}</span>
            </Button>
          )}
          content={({ onClose }) => <CreatePrice onClose={onClose} />}
        />
      )}
      {buttons.includes('jbusers.create') && (
        <ModalContainer
          lg
          title={LangConverter('Create Job House User', lang.value)}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{LangConverter('Create Job House User', lang.value)}</span>
            </Button>
          )}
          content={({ onClose }) => <CreateJobHouseUser onClose={onClose} />}
        />
      )}
      {buttons.includes('students.create') && (
        <ModalContainer
          lg
          title={LangConverter('Create Student', lang.value)}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{LangConverter('Create Student', lang.value)}</span>
            </Button>
          )}
          content={({ onClose }) => <CreateStudent onClose={onClose} />}
        />
      )}
      {buttons.includes('reseller-services.create') && (
        <ModalContainer
          lg
          title={LangConverter('Order Management', lang.value)}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{LangConverter('Order Management', lang.value)}</span>
            </Button>
          )}
          content={({ onClose }) => <PlaceOrderForm onClose={onClose} />}
        />
      )}
    </Header>
  );
}

export default TopBar;
