import usFlag from '../assets/images/us.svg';
import itlayFlag from '../assets/images/itlay.png';

export const LanguageData = [
  {
    img: usFlag,
    language: 'English',
    value: 'en',
  },
  {
    img: itlayFlag,
    language: 'Italian',
    value: 'it',
  },
];
export const ResellerTypes = [
  { label: 'Standard', value: 'standard' },
  { label: 'Basic', value: 'basic' },
  { label: 'Premium', value: 'premium' },
];
export const serviceTypes = [
  { label: 'Typing Courses', value: 'courses' },
  { label: 'Safety Courses Typing', value: 'safety' },
  { label: 'Eipass Typing', value: 'eipass' },
];
