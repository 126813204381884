import logo from 'assets/images/logo_icon.png';
import { LogoHolder, Img } from './Logo.styles';

function LogoComp() {
  return (
    <LogoHolder>
      <Img src={logo} alt="admin" />
    </LogoHolder>
  );
}

export default LogoComp;
