/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'helpers/useTranslation';
import { TooltipContainer, TooltipText } from './Tooltip.styles';

function Tooltip({ children, title, showPointer = true, width, type, isVerified }) {
  const { t } = useTranslation();
  return (
    <TooltipContainer>
      {children}
      <TooltipText showPointer={showPointer}>{t(title)}</TooltipText>
    </TooltipContainer>
  );
}

export default Tooltip;
