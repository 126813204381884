/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import adminService from 'services/admin.service';

import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import { LangConverter, getDateObject } from 'helpers/common';
import PermissionSelector from '../PermissionSelector';

function ServiceProviderForm({ onClose = () => {}, provider }) {
  const [form] = useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);
  const { lang } = useContextHook(AuthContext, { lang: 1 });
  useEffect(() => {
    if (provider) {
      const s = { ...provider };
      form.setFieldsValue(s);
      setState(s);
    }
  }, [provider]);
  const onSubmit = async data => {
    try {
      setLoading(true);
      const res = !provider
        ? await adminService.createServiceProvider(data)
        : await adminService.updateServiceProvider(provider._id, data);
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: res?.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid
        xs={1}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label={LangConverter('Provider Name', lang.value)}
          name="providerName"
          placeholder={LangConverter('Provider Name', lang.value)}
          rules={[{ required: true, message: LangConverter('Please enter service provider name', lang.value) }]}>
          <Field />
        </Form.Item>
      </Grid>
      <Button
        disabled={loading}
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        {provider ? LangConverter('Update', lang.value) : LangConverter('Create', lang.value)}
      </Button>
    </Form>
  );
}

export default ServiceProviderForm;
