/* eslint-disable no-shadow */
import React, { lazy, useState, useEffect, Suspense } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Loaders from 'components/atoms/Loaders';
import { useContextHook } from 'use-context-hook';
import Toast from 'components/molecules/Toast';
import PageTemplate from '../components/templates/PageTemplate';
import { AuthContext } from '../context/authContext';
import pages from '../nav.json';

const importView = file =>
  lazy(() =>
    import(`./${file}`).catch(() => {
      Toast({
        type: 'error',
        message: `Error in importing ${file}`,
      });
    }),
  );

export default function Index() {
  const { allowedPages, loading_user } = useContextHook(AuthContext, ['allowedPages', 'loading_user']);

  const history = useNavigate();

  const { view } = useParams();
  const metaViewData = pages;
  const [selectedView, setSelectedView] = useState([]);

  async function loadView(filtered) {
    const promise = filtered.map(async _view => {
      const View = await importView(_view.file);

      // eslint-disable-next-line no-use-before-define
      return <View key={_view.id} selectView={selectView} />;
    });
    Promise.all(promise).then(setSelectedView);
  }

  async function selectView(file) {
    const filtered = metaViewData.filter(elem => elem.file === file);

    loadView([filtered[0]]);
  }

  useEffect(() => {
    let fileToLoad = view;
    if (!allowedPages.includes(fileToLoad)) {
      fileToLoad = allowedPages.length > 0 ? allowedPages[0] : 'no-permissions';
    }

    if (fileToLoad === 'null' || fileToLoad === 'admin/null') {
      fileToLoad = 'no-permissions';
    }

    if (allowedPages.length === 1 && allowedPages[0] === 'no-permissions') {
      fileToLoad = 'no-permissions';
    }

    if (allowedPages.includes(fileToLoad)) {
      fileToLoad = allowedPages[allowedPages.indexOf(fileToLoad)];
    }

    history(`/admin/${fileToLoad}`, { replace: true });

    selectView(fileToLoad);
  }, [view, allowedPages, loading_user]);

  return loading_user ? (
    <Loaders pageLoader />
  ) : (
    <PageTemplate title={view} showTemplate={metaViewData?.filter(elem => elem.file === view)[0]?.navigations} topBar>
      <Suspense fallback={<Loaders pageLoader />}>{selectedView}</Suspense>
    </PageTemplate>
  );
}
