import React, { useEffect, useMemo, useState } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Select from 'components/atoms/Select';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import adminService from 'services/admin.service';
import { LangConverter } from 'helpers/common';

function PermissionForm({ permission, onClose = () => {} }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const { refetch } = useContextHook(AuthContext, ['refetch']);
  const { lang } = useContextHook(AuthContext, { lang: 1 });
  const {
    permissions_data: { permissions },
  } = adminService.GetPermissions({
    parentOnly: true,
  });
  const permissionOptions = useMemo(() => {
    if (permissions)
      return [
        {
          label: 'No-Parent',
          value: '$',
        },
        ...permissions,
      ];
    return [];
  }, [permissions]);
  useEffect(() => {
    if (permission) {
      form.setFieldsValue({
        can: permission.can,
        description: permission.description,
        route: permission.route,
        parent: permissionOptions.filter(({ value }) => permission.parent.includes(value)),
      });
    }
  }, [permission, permissionOptions]);

  const onSubmit = async data => {
    try {
      setLoading(true);
      if (permission) {
        await adminService.updatePermission(permission._id, {
          can: data.can,
          description: data.description,
          route: data.route,
          parent: data.parent.map(({ value }) => value),
        });
      } else {
        await adminService.createPermission({
          can: data.can,
          description: data.description,
          route: data.route,
          parent: data.parent.map(({ value }) => value),
        });
      }
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: LangConverter('permission saved successfully', lang.value),
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  return (
    <Form form={form} onSubmit={onSubmit}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label={LangConverter('Route', lang.value)}
          name="route"
          placeholder={LangConverter('Route', lang.value)}
          rules={[
            { required: true, message: LangConverter('Please enter can', lang.value) },
            {
              pattern: /^[a-zA-Z/_.-]+$/,
              message: LangConverter('Can can only contain letters, underscores, and dashes', lang.value),
            },
            {
              pattern: /^.{0,40}$/,
              message: LangConverter('Maximum Character Length Is 40', lang.value),
            },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          sm
          type="text"
          label={LangConverter('Can', lang.value)}
          name="can"
          placeholder={LangConverter('Can', lang.value)}
          rules={[
            { required: true, message: LangConverter('Please enter can', lang.value) },
            {
              pattern: /^[a-zA-Z._-]+$/,
              message: LangConverter('Can can only contain letters, underscores, and dashes', lang.value),
            },
            {
              pattern: /^.{0,40}$/,
              message: LangConverter('Maximum Character Length Is 40', lang.value),
            },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          sm
          type="text"
          label={LangConverter('Description', lang.value)}
          name="description"
          placeholder={LangConverter('Description', lang.value)}
          rules={[
            { required: true, message: LangConverter('Please enter description', lang.value) },
            {
              pattern: /^.{0,40}$/,
              message: LangConverter('Maximum Character Length Is 40', lang.value),
            },
          ]}>
          <Field />
        </Form.Item>

        <Form.Item
          sm
          options={permissionOptions}
          isSearchable
          isMulti
          name="parent"
          label={LangConverter('Parent', lang.value)}
          placeholder={LangConverter('Select Parent', lang.value)}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          rules={[
            { required: true, message: LangConverter('Select at least one role', lang.value) },
            {
              transform: value => !value?.length,
              message: LangConverter('Select at least one parent', lang.value),
            },
          ]}>
          <Select />
        </Form.Item>
      </Grid>

      <Button loading={loading} type="primary" htmlType="submit">
        Save
      </Button>
    </Form>
  );
}

export default PermissionForm;
