import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const resellerService = {
  _url: `${process.env.REACT_APP_BASE_API_URL}/reseller`,
  GetResellerDashboard(searchQuery, refetch) {
    const [dashboard, setDashboard] = useState({
      totalItems: 0,
      items: [],
    });
    const [status, setStatus] = useState(STATUS.SUCCESS);
    const { cancellablePromise } = useCancellablePromise();
    useEffect(() => {
      cancellablePromise(this.getResellerDashboard(searchQuery))
        .then(res => {
          setDashboard(res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.page,
      searchQuery?.searchText,
      searchQuery?.filterText,
      refetch,
    ]);
    return {
      dashboard_loading: status === STATUS.LOADING,
      dashboard_error: status === STATUS.ERROR ? status : '',
      dashboard_data: dashboard,
    };
  },
  GetResellerService(searchQuery, refetch) {
    const [service, setService] = useState({
      totalItems: 0,
      items: [],
    });
    const [status, setStatus] = useState(STATUS.SUCCESS);
    const { cancellablePromise } = useCancellablePromise();
    useEffect(() => {
      cancellablePromise(this.getResellerService(searchQuery))
        .then(res => {
          setService(res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.page,
      searchQuery?.searchText,
      searchQuery?.filterText,
      refetch,
    ]);
    return {
      service_loading: status === STATUS.LOADING,
      service_error: status === STATUS.ERROR ? status : '',
      service_data: service,
    };
  },
  GetResellerPrices(searchQuery, refetch) {
    const [pricelist, setPricelist] = useState({
      totalItems: 0,
      items: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getResellerPrices(searchQuery))
        .then(res => {
          setPricelist(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterPricelist,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      refetch,
    ]);

    return {
      pricelist_loading: status === STATUS.LOADING,
      pricelist_error: status === STATUS.ERROR ? status : '',
      pricelist_data: pricelist,
    };
  },
  GetStudents(searchQuery, refetch) {
    const [students, setStudents] = useState({
      totalItems: 0,
      students: [],
    });
    const [status, setStatus] = useState(STATUS.SUCCESS);
    const { cancellablePromise } = useCancellablePromise();
    useEffect(() => {
      cancellablePromise(this.getStudents(searchQuery))
        .then(res => {
          setStudents(res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      refetch,
      searchQuery.searchText,
      searchQuery.startDate,
      searchQuery.endDate,
      searchQuery.page,
      searchQuery.pageSize,
    ]);
    return {
      students_loading: status === STATUS.LOADING,
      students_error: status === STATUS.ERROR ? status : '',
      students_data: students,
    };
  },
  GetResellerServiceById(id, sessionId) {
    const [_resellerService, setResellerService] = useState({});
    const [status, setStatus] = useState(STATUS.SUCCESS);
    const { cancellablePromise } = useCancellablePromise();
    useEffect(() => {
      cancellablePromise(this.getResellerServiceById(id, sessionId))
        .then(res => {
          setResellerService(res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [id]);
    return {
      resellerService_loading: status === STATUS.LOADING,
      resellerService_error: status === STATUS.ERROR ? status : '',
      resellerService_data: _resellerService,
    };
  },

  GetComissionOrders(searchQuery, refetch) {
    const [orders, setOrders] = useState({
      totalItems: 0,
      orders: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getComissionOrders(searchQuery))
        .then(res => {
          setOrders(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.searchText,
      searchQuery?.startDate,
      searchQuery?.endDate,
      refetch,
    ]);

    return {
      orders_loading: status === STATUS.LOADING,
      orders_error: status === STATUS.ERROR ? status : '',
      orders_data: orders,
    };
  },
  GetComissionDetails(searchQuery, refetch) {
    const [commissionData, setCommissionData] = useState({
      comission_paid: 0,
      comission_pending: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getCommissionDetails(searchQuery))
        .then(res => {
          setCommissionData(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [searchQuery?.startDate, searchQuery?.endDate, refetch]);

    return {
      commission_loading: status === STATUS.LOADING,
      commission_error: status === STATUS.ERROR ? status : '',
      commission_data: commissionData,
    };
  },

  GetStudentSessions(searchQuery, refetch) {
    const [dashboard, setDashboard] = useState({
      totalItems: 0,
      items: [],
    });
    const [status, setStatus] = useState(STATUS.SUCCESS);
    const { cancellablePromise } = useCancellablePromise();
    useEffect(() => {
      cancellablePromise(this.getStudentSessions(searchQuery))
        .then(res => {
          setDashboard(res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [refetch]);
    return {
      dashboard_loading: status === STATUS.LOADING,
      dashboard_error: status === STATUS.ERROR ? status : '',
      dashboard_data: dashboard,
    };
  },
  GeneratePaymentCertificate(orderId) {
    const [pdf, setPdf] = useState(null);
    const [status, setStatus] = useState(STATUS.LOADING);
    const { cancellablePromise } = useCancellablePromise();
    useEffect(() => {
      cancellablePromise(this.generateSelfCertificate(orderId))
        .then(res => {
          setPdf(res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [orderId]);
    return {
      pdf_loading: status === STATUS.LOADING,
      pdf_error: status === STATUS.ERROR ? status : '',
      pdf,
    };
  },
  async getResellerDashboard({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    filterText = '',
  }) {
    const res = await Fetch.get(
      `${this._url}/dashboard?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&filterText=${filterText}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async placeOrder(data) {
    const res = await Fetch.post(`${this._url}/place-order`, data);
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },

  async updateOrder(id, data) {
    const res = await Fetch.put(`${this._url}/update-order/${id}`, data);
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },

  async bookSession(payload, sessionId) {
    const res = await Fetch.post(`${this._url}/book-session/${sessionId}`, payload);
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async udpateSession(payload, sessionId) {
    const res = await Fetch.put(`${this._url}/update-session/${sessionId}`, payload);
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },

  async getResellerService({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    filterText = '',
    getAll = false,
  }) {
    const res = await Fetch.get(
      `${this._url}/reseller-service?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&filterText=${filterText}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async getResellerPrices({ page = 1, pageSize = 10, searchText = '', startDate = '', endDate = '', getAll = false }) {
    const res = await Fetch.get(
      `${this._url}/services?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },

  async getStudents({ page = 1, pageSize = 10, searchText = '', startDate = '', endDate = '', getAll = false }) {
    let res = await Fetch.get(
      `${this._url}/students?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        students: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createStudents(payload) {
    let res = await Fetch.post(`${this._url}/create-student`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateStudents(id, payload) {
    let res = await Fetch.put(`${this._url}/update-student/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteStudents(id) {
    let res = await Fetch.delete(`${this._url}/delete-student/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getResellerServiceById(id, sessionId) {
    let res = await Fetch.get(`${this._url}/reseller-service/${id}/${sessionId}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async serviceProvidersFiltersOptions({ getAll = true }) {
    let res = await Fetch.get(`${this._url}/services?getAll=${getAll}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        options: res?.items?.map(({ providerId: { _id, providerName } }) => ({ label: providerName, value: _id })),
      };
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async getComissionOrders({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    getAll = false,
    resellerId = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/comission-orders?resellerId=${resellerId}&page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        orders: res?.items,
        totalItems: res?.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getCommissionDetails({ startDate = '', endDate = '', resellerId = '', getAll = false }) {
    let res = await Fetch.get(
      `${this._url}/comission-details/${resellerId}?startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        comission_paid: res?.comission_paid,
        comission_pending: res?.comission_pending,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async requestAdmin(id, payload) {
    let res = await Fetch.post(`${this._url}/session-request-admin/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getStudentSessions({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    filterText = '',
    studentId = '',
  }) {
    const res = await Fetch.get(
      `${this._url}/student-sessions/${studentId}?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&filterText=${filterText}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },
  async generateSelfCertificate(orderId) {
    let res = await Fetch.get(`${this._url}/generate-self-certificate/${orderId}`, {
      responseType: 'arraybuffer',
      responseEncoding: 'binary',
      method: 'GET',
    });
    if (res.status >= 200 && res.status < 300) {
      res = await res?.arrayBuffer();
      return res;
    }
    // const { message } = await res.json();
    throw Error('Something went wrong');
  },
};

export default resellerService;
