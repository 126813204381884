import 'styled-components/macro';
import DataTabs from 'components/molecules/DataTabs';
import DetailsCard from 'components/molecules/DetailsCard';
import Grid from 'components/atoms/Grid';
import InfoCard from 'components/molecules/InfoCard';
import { useContextHook } from 'use-context-hook';
import { format } from 'date-fns';
import { LangConverter, getDateObject } from 'helpers/common';
import { AuthContext } from 'context/authContext';

export default function Index() {
  const { lang } = useContextHook(AuthContext, { lang: 1 });
  const { user } = useContextHook(AuthContext, {
    user: 1,
  });
  const content = [
    {
      label: LangConverter('Profile', lang.value),
      content: (
        <DetailsCard>
          <Grid xs={1} sm={2} gap={20}>
            <InfoCard title={LangConverter('First Name', lang.value)} value={user?.firstName} $unStyled />
            <InfoCard title={LangConverter('Last Name', lang.value)} value={user?.lastName} $unStyled />
            <InfoCard title={LangConverter('Email', lang.value)} value={user.email} $unStyled />
            <InfoCard
              title={LangConverter('User Since', lang.value)}
              value={format(getDateObject(user.created_at), 'dd MMM yyyy')}
              $unStyled
            />
            <InfoCard title={LangConverter('User Role', lang.value)} value={user.role_type.join(',')} $unStyled />
          </Grid>
        </DetailsCard>
      ),
    },
    // {
    //   label: 'Password',
    //   content: <PasswordUpdateForm />,
    // },
  ];
  return <DataTabs data={content} />;
}
