/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import adminService from 'services/admin.service';

import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import { LangConverter } from 'helpers/common';
import Select from 'components/atoms/Select';
import { useTranslation } from 'helpers/useTranslation';
import { ResellerTypes } from 'helpers/constants';
import GridCol from 'components/atoms/GridCol';

function SellerForm({ onClose = () => {}, reseller, isReseller }) {
  console.log({ reseller, isReseller });
  const [form] = useForm();
  const [state, setState] = useState({
    roleType: 'SELLER',
  });
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);
  const { lang, user } = useContextHook(AuthContext, { lang: 1, user: 1 });
  const { t } = useTranslation();
  console.log({ user, state });

  const onSelectReseller = async ({ target: { value } }) => {
    try {
      const selectedReseller = { resellerId: { label: value?.label, value: value?.value } };
      form.setFieldsValue(selectedReseller);
      const selectedResellerId = selectedReseller?.resellerId?.value;

      setState(prev => ({ ...prev, resellerId: selectedReseller?.resellerId?.value, selectKey: Math.random() }));
    } catch (error) {
      Toast({
        type: 'error',
        message: error?.message,
      });
    }
  };

  useEffect(() => {
    if (reseller) {
      // const resellerId = {
      //   label: `Name:${reseller?.resellerName}, Email:${reseller?.resellerEmail}`,
      //   value: reseller?._id,
      // };

      const s = { ...reseller };
      form.setFieldsValue(s);

      setState(s);

      onSelectReseller({
        target: {
          value: {
            label: `Name:${reseller?.resellerName}, Email:${reseller?.resellerEmail}`,
            value: reseller?.resellerId,
          },
        },
      });
    } else {
      form.setFieldsValue({ password: '', email: '' });
      form.setFieldsError({ password: { message: undefined }, email: { message: undefined } });
    }

    if (isReseller) {
      onSelectReseller({
        target: {
          value: {
            label: ``,
            value: user?._id,
          },
        },
      });
    }
  }, [reseller]);
  const onSubmit = async data => {
    const payload = { ...data, resellerUid: state?.resellerId, roleType: 'SELLER' };
    delete payload.resellerId;
    console.log({ payload });
    // return;
    try {
      setLoading(true);
      const res = !reseller
        ? await adminService.createReseller(payload)
        : await adminService.updateReseller(reseller._id, payload);
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: res?.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  const handleResellerSearch = async __ => {
    try {
      const response = await adminService.getResellers({
        page: 1,
        pageSize: 10,
        searchText: __,
        startDate: '',
        endDate: '',
        filterProviders: '',
      });
      const options = response?.resellers?.map(_ => ({
        value: _?._id,
        label: `${t('Name')}: ${_?.firstName} ${_?.lastName}, ${t('Email')}: ${_?.email}`,
      }));
      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      {!reseller && !isReseller && (
        <GridCol xs={12}>
          <Form.Item
            sm
            type="text"
            isDisabled={reseller}
            label="Reseller"
            name="resellerId"
            open
            async
            defaultOptions
            filterOption={false}
            loadOptions={handleResellerSearch}
            onChange={onSelectReseller}
            placeholder="Select Reseller"
            rules={[{ required: true, message: 'Please select Reseller' }]}>
            <Select />
          </Form.Item>
        </GridCol>
      )}
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label={LangConverter('First Name', lang.value)}
          name="firstName"
          placeholder={LangConverter('First Name', lang.value)}
          rules={[{ required: true, message: LangConverter('Please enter first name', lang.value) }]}>
          <Field />
        </Form.Item>
        <Form.Item
          sm
          type="text"
          label={LangConverter('Last Name', lang.value)}
          name="lastName"
          placeholder={LangConverter('Last Name', lang.value)}
          rules={[{ required: true, message: LangConverter('Please enter last name', lang.value) }]}>
          <Field />
        </Form.Item>
        {!reseller && (
          <Form.Item
            type="password"
            sm
            label={LangConverter('Password', lang.value)}
            name="password"
            placeholder={LangConverter('Password', lang.value)}
            rules={[
              {
                required: true,
              },
              { password: true },
            ]}>
            <Field />
          </Form.Item>
        )}
        <Form.Item
          type="email"
          sm
          label={LangConverter('email', lang.value)}
          name="email"
          placeholder={LangConverter('E-mail', lang.value)}
          rules={[
            {
              required: true,
              message: LangConverter('Email is required.', lang.value),
            },
            { email: true },
          ]}>
          <Field />
        </Form.Item>

        <Form.Item
          type="email"
          sm
          label={LangConverter('Notification Email', lang.value)}
          name="notificationEmail"
          placeholder={LangConverter('E-mail', lang.value)}
          rules={[
            {
              required: true,
              message: LangConverter('Notification email is required.', lang.value),
            },
            { email: true },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          sm
          label={LangConverter('Phone Number', lang.value)}
          name="phoneNumber"
          placeholder="(XXX) XXX-XXXX"
          rules={[
            {
              required: true,
              message: LangConverter('Phone Number is required', lang.value),
            },
            { changeRegex: 'phone_number' },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          sm
          label={LangConverter('Mobile Number', lang.value)}
          name="mobileNumber"
          placeholder="(XXX) XXX-XXXX"
          rules={[{ changeRegex: 'phone_number' }]}>
          <Field />
        </Form.Item>
      </Grid>
      <Button
        disabled={loading}
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        {reseller ? LangConverter('Update', lang.value) : LangConverter('Create', lang.value)}
      </Button>
    </Form>
  );
}

export default SellerForm;
