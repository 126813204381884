/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import adminService from 'services/admin.service';

import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import { LangConverter } from 'helpers/common';
import resellerService from 'services/resellers.service';
import GridCol from 'components/atoms/GridCol';

function CreateStudent({ onClose = () => {}, student, reseller }) {
  const [form] = useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);
  const { lang } = useContextHook(AuthContext, { lang: 1 });
  useEffect(() => {
    if (student) {
      const s = { ...student };
      const timeSplit = student.dateOfBirth.split('-');
      const year = timeSplit[0];
      const month = timeSplit[1];
      const day = timeSplit[2].split('T')[0];
      s.dateOfBirth = `${day}/${month}/${year}`;
      form.setFieldsValue(s);
      setState(s);
    }
  }, [student]);
  useEffect(() => {
    if (reseller) {
      const s = { resellerEmail: reseller?.email };

      form.setFieldsValue(s);
      setState(s);
    }
  }, []);

  const onSubmit = async data => {
    const payload = { ...data, roleType: 'STUDENT' };

    if (reseller?.email) {
      delete payload.resellerEmail;
    }
    try {
      setLoading(true);
      const res = reseller
        ? await adminService.createStudent(reseller?._id, payload)
        : !student
        ? await resellerService.createStudents(payload)
        : await resellerService.updateStudents(student._id, payload);
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: res?.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: LangConverter(ex.message, lang.value),
      });
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label={LangConverter('First Name', lang.value)}
          name="firstName"
          placeholder={LangConverter('First Name', lang.value)}
          rules={[{ required: true, message: LangConverter('Please enter first name', lang.value) }]}>
          <Field />
        </Form.Item>
        <Form.Item
          sm
          type="text"
          label={LangConverter('Last Name', lang.value)}
          name="lastName"
          placeholder={LangConverter('Last Name', lang.value)}
          rules={[{ required: true, message: LangConverter('Please enter last name', lang.value) }]}>
          <Field />
        </Form.Item>
        {/* <Form.Item
          sm
          type="text"
          label={LangConverter('Username', lang.value)}
          name="username"
          placeholder={LangConverter('User Name', lang.value)}
          rules={[{ required: true, message: LangConverter('Please enter username', lang.value) }]}>
          <Field />
        </Form.Item> */}
        {!student && (
          <Form.Item
            type="password"
            sm
            label={LangConverter('Password', lang.value)}
            name="password"
            placeholder={LangConverter('Password', lang.value)}
            rules={[
              {
                required: true,
              },
              { password: true },
            ]}>
            <Field />
          </Form.Item>
        )}
        <Form.Item
          type="email"
          sm
          label={LangConverter('Email', lang.value)}
          name="email"
          placeholder={LangConverter('E-mail', lang.value)}
          rules={[
            {
              required: true,
              message: LangConverter('Email is required.', lang.value),
            },
            { email: true },
          ]}>
          <Field />
        </Form.Item>
        {reseller?.email && (
          <Form.Item
            type="email"
            sm
            label={LangConverter('Reseller', lang.value)}
            name="resellerEmail"
            placeholder={LangConverter('E-mail', lang.value)}
            value={reseller?.email}
            disabled
            rules={[
              {
                required: true,
                message: LangConverter('Email is required.', lang.value),
              },
              { email: true },
            ]}>
            <Field />
          </Form.Item>
        )}
        {/* <Form.Item
          type="text"
          sm
          label={LangConverter('Codice Fiscale', lang.value)}
          name="codiceFiscale"
          placeholder={LangConverter('Codice Fiscale', lang.value)}
          rules={[
            {
              required: true,
              message: LangConverter('Codice fiscale is required', lang.value),
            },
          ]}>
          <Field />
        </Form.Item> */}
        <Form.Item
          type="number"
          sm
          label={LangConverter('Partita Iva', lang.value)}
          name="partitaIva"
          placeholder="123456789"
          rules={[
            {
              pattern: /^[0-9]{11}$/,
              message: LangConverter('Enter a valid 11 digits Partita Iva', lang.value),
            },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          sm
          name="dateOfBirth"
          label={LangConverter('Date of Birth', lang.value)}
          selected={state?.selectedDate}
          onChange={date => {
            form.setFieldsValue({ dateOfBirth: date.target.value ?? '' });
            setState(prev => ({ ...prev, selectedDate: date.target.value }));
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText={LangConverter('Select Date Of Birth', lang.value)}
          type="datepicker"
          rules={[{ required: true, message: LangConverter('Date Of Birth is required', lang.value) }]}>
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          sm
          label={LangConverter('Place of Birth', lang.value)}
          name="placeOfBirth"
          placeholder={LangConverter('Place of Birth', lang.value)}
          rules={[
            {
              required: true,
              message: LangConverter('Place of birth is required', lang.value),
            },
          ]}>
          <Field />
        </Form.Item>
        {/* <Form.Item
          type="email"
          sm
          label={LangConverter('Notification Email', lang.value)}
          name="notificationEmail"
          placeholder={LangConverter('E-mail', lang.value)}
          rules={[
            {
              required: true,
              message: LangConverter('Notification email is required.', lang.value),
            },
            { email: true },
          ]}>
          <Field />
        </Form.Item> */}
        <Form.Item
          type="text"
          sm
          label={LangConverter('Phone Number', lang.value)}
          name="phoneNumber"
          placeholder="(XXX) XXX-XXXX"
          rules={[
            {
              required: true,
              message: LangConverter('Phone Number is required', lang.value),
            },
            { changeRegex: 'phone_number' },
          ]}>
          <Field />
        </Form.Item>
        {/* <Form.Item
          type="text"
          sm
          label={LangConverter('Mobile Number', lang.value)}
          name="mobileNumber"
          placeholder="(XXX) XXX-XXXX"
          rules={[{ changeRegex: 'phone_number' }]}>
          <Field />
        </Form.Item> */}
        <Form.Item
          type="text"
          sm
          label={LangConverter('Address', lang.value)}
          name="address"
          placeholder="Address"
          rules={[
            {
              required: true,
              message: LangConverter('Address is required', lang.value),
            },
          ]}>
          <Field />
        </Form.Item>
        {/* <Form.Item
          type="text"
          sm
          label={LangConverter('Area Code', lang.value)}
          name="areaCode"
          placeholder={LangConverter('Area', lang.value)}
          rules={[
            {
              required: true,
              message: LangConverter('Area Code is required', lang.value),
            },
          ]}>
          <Field />
        </Form.Item> */}
        <Form.Item
          type="text"
          sm
          label={LangConverter('Zip Code', lang.value)}
          name="zipCode"
          placeholder={LangConverter('Zip Code', lang.value)}
          rules={[
            {
              required: true,
              message: LangConverter('Zip Code is required', lang.value),
            },
          ]}>
          <Field />
        </Form.Item>
        {/* <Form.Item
          type="text"
          sm
          label={LangConverter('Telegram Id', lang.value)}
          name="telegramId"
          placeholder={LangConverter('Telegram Id', lang.value)}>
          <Field />
        </Form.Item> */}
        <Form.Item
          type="text"
          sm
          label={LangConverter('Qualification', lang.value)}
          name="qualification"
          placeholder={LangConverter('Qualification', lang.value)}>
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          sm
          label={LangConverter('Year of Pre Enrollment', lang.value)}
          name="yearOfEnrollment"
          placeholder={LangConverter('Year of Pre Enrollment', lang.value)}>
          <Field />
        </Form.Item>
      </Grid>
      <GridCol xs={1}>
        <Form.Item
          type="chooseFile"
          label={LangConverter('Upload Documents', lang.value)}
          name="documents"
          pdf
          value={state?.documents}
          onChange={({ target }) => {
            setState(prev => ({ ...prev, documents: target?.value }));
            form.setFieldsValue({ documents: target?.value });
          }}>
          <Field />
        </Form.Item>
      </GridCol>
      <Button
        disabled={loading}
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        {student ? LangConverter('Update', lang.value) : LangConverter('Create', lang.value)}
      </Button>
    </Form>
  );
}

export default CreateStudent;
