/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState } from 'react';
import { createContextHook } from 'use-context-hook';

const context = {};

export const FiltersContext = createContextHook(context);

export function FiltersContextProvider(props) {
  const [filterToggle, setFilterToggle] = useState(false);
  const toggleFilter = () => {
    setFilterToggle(!filterToggle);
  };
  return (
    <FiltersContext.Provider
      value={{
        filterState: filterToggle,
        toggleFilter,
        setFilterToggle,
      }}>
      {props.children}
    </FiltersContext.Provider>
  );
}
